import Mock from "../mock";

const database = {
  information: {
    name: 'Xinjie Li',
    aboutContent: `Hi👋🏻 I'm Estella
    👩🏻‍💻 I’m a fullstack web developer
    🌟 I'm passionate about combining the power of technology and art
    💜 The technologies I'm enjoy working with: React, Redux, NodeJS...
    🌱 I’m currently learning GraphQL, NextJS...
    📷 Photographer
    🦦 Animal lover
    🎬 Movie fan
    🍓 Whole foods diet`,
    phone: '513-206-3466',
    email: 'lxjestella@gmail.com',
    language: 'English, Mandrain',
    freelanceStatus: 'Available',
    socialLinks: {
      linkedin: 'https://www.linkedin.com/in/xinjie-estella-li/',
      dribbble: '',
      github: 'https://github.com/Estella-lxj'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/resume12292020.pdf'
  },
  services: [
    {
      title: "Web Development",
      icon: 'code',
      details: "Experienced with full stack web development. React for frontend and Node.js for backend."
    },
    {
      title: "UI/UX Design",
      icon: 'color-pallet',
      details: "Passionate about building something that is a joy to use. Make life easier for everyone."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Interested in mobile development. Currently learning React Native and building projects."
    },
  ],
  reviews: [
    {
      id: 1,
      content: "During the past 9 months, Estella demonstrated strong self-learning capability, solid analytical skills and excellent logical thinking ability.",
      author: {
        name: 'Amanda Xu',
        designation: 'Strategy manager, GE'
      },
    },
    {
      id: 2,
      content: "I was particularly impressed that Estella would take up new-based technologies as requested and combine those newly learned technology with those she already knew in a seemingly effortless way.",
      author: {
        name: 'John Blackwell',
        designation: 'Senior Software Engineer, Aeroseal'
      },
    },
    {
      id: 3,
      content: "Her outstanding performance has been recognized by the whole team and outpaced all the interns we have hired in the past 3 years.",
      author: {
        name: 'Lilly Rong',
        designation: 'Senior Growth Initiatives Leader, GE'
      },
    },
  ],
  skills: [
    {
      title: "Javascript ES6+",
      value: 95
    },
    {
      title: "HTML5 & CSS3",
      value: 90
    },
    {
      title: "React",
      value: 90
    },
    {
      title: "Redux",
      value: 85
    },
    {
      title: "NodeJS",
      value: 80
    },
    {
      title: "MongoDB",
      value: 75
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Real-time Chat Room",
      subtitle: "Many-to-many chatting, changing chat rooms and pseudo names built on Socket.io.",
      imageUrl: "/images/portfolio-image-1.png",
      largeImageUrl: ["/images/portfolio-image-1-lg.png"],
      url: 'https://real-time-chat-estella.netlify.app',
    },
    {
      id: 2,
      title: "User Management",
      subtitle: "MERN stack App provides CRUD operations, dynamic searching and sorting.",
      imageUrl: "/images/portfolio-image-2.png",
      largeImageUrl: ["/images/portfolio-image-2-lg.png"],
      url: 'https://user-management-estella.netlify.app/'
    },
    {
      id: 3,
      title: "Feedback Collection",
      subtitle: "Sending out surveys to multiple clients, using Google OAuth 2.0, Stripe and Send Grid API.",
      imageUrl: "/images/portfolio-image-3.png",
      largeImageUrl: ["/images/portfolio-image-3-lg.png"],
    },
    {
      id: 4,
      title: "Face Detection",
      subtitle: "Detecting faces through image URL inputs. Register and give it a try!",
      imageUrl: "/images/portfolio-image-4.png",
      largeImageUrl: ["/images/portfolio-image-4-lg.png"],
      url: 'https://face-detection-estella.netlify.app/',
    },
    {
      id: 5,
      title: "Robot Friends",
      subtitle: "Dynamically searching robots, Redux for state management.",
      imageUrl: "/images/portfolio-image-5.png",
      largeImageUrl: ["/images/portfolio-image-5-lg.png"],
      url: 'https://robot-friends-estella.netlify.app/',
    },
    {
      id: 6,
      title: "Live Streaming",
      subtitle: "Recording desktop and broadcasting the real-time video to viewers’ browser.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Self-driving Car",
      subtitle: "Visualizing Navigation Satellite fix and torque controlled joints states in real-time.",
      imageUrl: "/images/portfolio-image-7.png",
      largeImageUrl: ["/images/portfolio-image-7-lg.png"],
      url: 'https://self-driving-car-data-visualization-estella.netlify.app/',
    },
    {
      id: 8,
      title: "Rick and Morty",
      subtitle: "A Next.js app which can query characters data from the GraphQL API.",
      imageUrl: "/images/portfolio-image-8.png",
      largeImageUrl: ["/images/portfolio-image-8-lg.png"],
      url: 'https://rick-and-morty-next-graphql-estella-lxj.vercel.app/',
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "04/2021 - present",
        position: "Full Stack Engineer",
        company: "Walmart eCommerce",
        details: "• Developed Walmart Mexico & Sam's club eCommerce website using HTML5, CSS3, JavaScript, React and Redux with cross-browser compatibility & responsive design, serving over 2M customers. • Integrated Walmart Mexico with monorepo to unify customer journey across hallways using NX, Next & TypeScript. • Built orchestration layer server for Membership sub-graph using GraphQL federation, handling authorization and effi‐ ciently providing data across web application and backend micro-service. • Created functional test script using Cypress for web application and unit test scripts for GraphQL federated server using Jest, with code coverage reaching 100%.",
      },
      {
        id: 2,
        year: "06/2020 - 11/2020",
        position: "Software Engineer Intern",
        company: "Aeroseal",
        details: "• Developed a full stack web user interface allowing international dealers to search project information for potential sales increasing, and generate customized certificates for personalized customer service.\n• Built modern React interface modules using Hooks; created reusable components for future reference.\n• Implemented Python Flask web framework to create RESTful API backend service.\n• Utilized MongoDB to store and process gigabytes of data; designed and implemented algorithms to fetch data from datastore and display them on the frontend.",
      },
      {
        id: 3,
        year: "01/2017 - 08/2017",
        position: "Business Intelligence Intern",
        company: "General Electric",
        details: "• Contributed with Africa EPC power construction project and delivered consolidated, actionable data and reports.\n • Built segmentation profiles with distributed energy data to assist the predictive analytics team in modeling efforts.\n • Experienced with analysis, regression and forecasting tools in Python and Pandas library."
      },
      {
        id: 4,
        year: "08/2015 - 12/2015",
        position: "Digital Factory Intern",
        company: "Siemens",
        details: "• Designed and developed a real-time monitoring dashboard for the engineering team to operate and monitor the on‐ site equipment working process and enhance their using experience.\n• Built dashboard in Node-red based on Node.js; wired together hardware devices, APIs and online services.\n • Utilized MQTT broker to publish and subscribe real-time messages between devices and web dashboard."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2020",
        graduation: "M.S. in Electrical Engineering",
        university: "University of Cincinnati",
        details: "Graduate school's scholarship"
      },
      {
        id: 2,
        year: "2017 - 2018",
        graduation: "B.S. in Electrical Engineering",
        university: "University of Cincinnati",
        details: "Selected courses: Python, Intelligent System"
      },
      {
        id: 3,
        year: "2013 - 2017",
        graduation: "B.S. in Electrical Engineering",
        university: "Chongqing University",
        details: "Selected courses: C++, MATLAB, Embedded System"
      }
    ]
  },
  contactInfo: {
    phoneNumbers: ['+1-513-206-3466',],
    emailAddress: ['lxjestella@gmail.com',],
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});